import styled from 'styled-components'
import { Table } from 'antd'

export const StyledModeloramasContent = styled.div`
  height: 100vh;
  margin: 2%;
`

export const TitleOfModelorama = styled.h1`
  font-size: 18px;
  font-weight: 600;
  color: #0b4793;

  @media all and (max-width: 767px) {
    display: none;
  }
`

export const TableModeloramas = styled(Table)`
  display: block;

  @media all and (max-width: 767px) {
    display: none;
  }
`

export const TableModeloramasMobile = styled(Table)`
  display: none;

  @media all and (max-width: 767px) {
    display: block;
  }
`
