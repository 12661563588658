// Packages
import React from 'react'

// Components
import WithPrivateRoute from '../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../components/SEO'
import ModeloramasContainer from '../../containers/ModeloramasContainer'
import LayoutMain from '../../layout'

const Mimodelorama: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Modeloramas" />
      <WithPrivateRoute component={ModeloramasContainer} />
    </LayoutMain>
  )
}

export default Mimodelorama
