//Packages
import React, { useEffect, useState } from 'react'
import { Tabs, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

// Styled Componets
import {
  StyledModeloramasContent,
  TableModeloramas,
  TableModeloramasMobile,
  TitleOfModelorama
} from './styledcomponents'

// Actions
import { requestDataProfile } from '../../redux/ducks/profile'
import { navigate } from 'gatsby'

const { TabPane } = Tabs

const columns_displays = [
  {
    title: 'ID Dispositivo',
    dataIndex: 'deviceID',
    key: 'deviceID'
  },
  {
    title: 'Modelo',
    dataIndex: 'model',
    key: 'model'
  },
  {
    title: 'Fabricante',
    dataIndex: 'vendor',
    key: 'vendor'
  }
]

const columns_modeloramas = [
  {
    title: 'ID Modelorama',
    dataIndex: 'storeID',
    key: 'storeID'
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Tipo',
    dataIndex: 'storeType',
    key: 'storeType',
    render: (row: StoreTypes.StoreType) => <span>{row.description}</span>
  },
  {
    title: 'DRV',
    dataIndex: 'agency',
    key: 'agency',
    render: (row: StoreTypes.Agency) => <span>{row.agencyName}</span>
  },
  {
    title: 'Teléfono',
    dataIndex: 'phone',
    key: 'phone'
  }
]

const columns_modeloramas_mobile = [
  {
    title: 'ID Modelorama',
    dataIndex: 'storeID',
    key: 'storeID'
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Tipo',
    dataIndex: 'storeType',
    key: 'storeType',
    render: (row: StoreTypes.StoreType) => <span>{row.description}</span>
  }
]

interface ProfileState {
  profile: any
}

const ModeloramasContainer: React.FC = () => {
  const [stores, setStores] = useState([])
  const [devices, setDevices] = useState([])
  const { user_data, isLoading } = useSelector(
    (state: ProfileState) => state.profile
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (isEmpty(user_data)) {
      dispatch(requestDataProfile())
    }
  }, [])

  useEffect(() => {
    let itemsStores: any = []
    let itemsDevices: any = []

    if (!user_data.getProfile?.stores) {
      navigate('/mimodelorama/storeNotFound')
      return
    }

    if (!isEmpty(user_data) && !isLoading) {
      user_data?.getProfile.stores.forEach((devices: any) => {
        if (devices.devices !== null) {
          devices.device?.forEach((itemDevice: any) => {
            itemsDevices = [
              ...itemsDevices,
              {
                deviceID: itemDevice.deviceID,
                model: itemDevice.model,
                vendor: itemDevice.vendor,
                key: itemDevice.deviceID
              }
            ]
          })
        }
      })

      user_data?.getProfile.stores.forEach((element: any) => {
        itemsStores = [
          ...itemsStores,
          {
            storeID: element.storeID,
            name: element.name,
            storeType: element.storeType,
            agency: element.agency,
            phone: element.phone,
            key: element.storeID
          }
        ]
      })
      setStores(itemsStores)
      setDevices(itemsDevices)
    }
  }, [user_data])

  return (
    <StyledModeloramasContent>
      <TitleOfModelorama>Mis Dispositivos y Modeloramas</TitleOfModelorama>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Dispositivos" key="1">
          <Table
            columns={columns_displays}
            dataSource={devices}
            pagination={false}
            loading={isLoading}
          />
        </TabPane>
        <TabPane tab="Modeloramas" key="2">
          <TableModeloramas
            columns={columns_modeloramas}
            dataSource={stores}
            pagination={false}
            loading={isLoading}
          />
          <TableModeloramasMobile
            columns={columns_modeloramas_mobile}
            dataSource={stores}
            pagination={false}
            loading={isLoading}
          />
        </TabPane>
      </Tabs>
    </StyledModeloramasContent>
  )
}

export default ModeloramasContainer
